<script>
/**
 * Login component
 */
export default {
  data () {
    return {
      email: '',
      password: '',
      tryingToLogIn: false,
      isAuthError: false
    }
  },
  props: {
    submitUrl: {
      type: String,
      required: true
    },
    authError: {
      type: String,
      required: false,
      default: () => null
    },
    enteredEmail: {
      type: String,
      required: false,
      default: () => null
    }
  },
  mounted () {
    if (this.enteredEmail) {
      this.$set(this, 'email', this.enteredEmail)
    }
    this.isAuthError = !!this.authError
  }
}
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
      <div class="card overflow-hidden">
        <div class="bg-soft-primary">
          <div class="row">
            <div class="col-8">
              <div class="text-primary p-4">
<!--                <h5 class="text-primary">{{ $t("login.welcomeBack") }} !</h5>-->
                <h5 class="text-primary">{{ $t("login.personalArea") }} !</h5>
                <p>{{ $t("login.signInToContinue") }}</p>
              </div>
            </div>
            <div class="col-4 align-self-end">
              <img src="/images/profile-img.png" alt class="img-fluid"/>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div>
            <a href="/">
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="/images/logo.svg" alt height="34"/>
                </span>
              </div>
            </a>
          </div>

          <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

          <b-form class="p-2" :action="submitUrl" method="POST">
            <slot/>
            <b-form-group id="input-group-1" label="Email" label-for="input-1">
              <b-form-input id="input-1" name="email" v-model="email" type="text"
                            v-bind:placeholder="$t('login.placeholderEmail')"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" v-bind:label="$t('login.password')" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="password" name="password"
                type="password"
                v-bind:placeholder="$t('login.placeholderPassword')"
              ></b-form-input>
            </b-form-group>
            <div class="custom-control custom-checkbox">
              <input id="customControlInline" type="checkbox" class="custom-control-input"/>
              <label class="custom-control-label" for="customControlInline">{{ $t('login.rememberMe') }}</label>
            </div>
            <div class="mt-3">
              <b-button type="submit" variant="primary" class="btn-block">{{ $t('login.logIn') }}</b-button>
            </div>
            <div class="mt-4 text-center">
              <a href="/password/reset" class="text-muted">
                <i class="mdi mdi-lock mr-1"></i> {{ $t('login.forgotYourPassword') }}?
              </a>
            </div>
          </b-form>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->

      <div class="mt-5 text-center">
        <p>
          {{ $t('login.dontHaveAnAccount') }} ?
          <a
            href="/register"
            class="font-weight-medium text-primary"
          >{{ $t('login.signupNow') }}</a>
        </p>
        <p>
          © {{ new Date().getFullYear() }} <a href="/">InComCRM</a>
<!--          Skote. Crafted with-->
<!--          <i class="mdi mdi-heart text-danger"></i> by Themesbrand-->
        </p>
      </div>
      <!-- end row -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>
