<script>
/**
 * Register component
 */
export default {
  data() {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      isRegisterError: false,
      registerSuccess: false
    }
  },
  props: {
    submitUrl: {
      type: String,
      required: true
    },
    regError: {
      type: String,
      required: false,
      default: () => null
    }
  },
  mounted() {
    this.isRegisterError = !!this.regError
  }
}
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
      <div class="card overflow-hidden">
        <div class="bg-soft-primary">
          <div class="row">
            <div class="col-7">
              <div class="text-primary p-4">
                <h5 class="text-primary">Бесплатная регистрация</h5>
                <p>Получите доступ в систему прямо сейчас.</p>
              </div>
            </div>
            <div class="col-5 align-self-end">
              <img src="/images/profile-img.png" alt class="img-fluid"/>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div>
            <a href="/">
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="/images/logo.svg" alt class="rounded-circle" height="34"/>
                </span>
              </div>
            </a>
          </div>

          <b-alert
              v-model="registerSuccess"
              class="mt-3"
              variant="success"
              dismissible
          >{{ $t('register.registrationSuccessful') }}.
          </b-alert>

          <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}</b-alert>

          <b-form class="p-2" :action="submitUrl" method="POST">
            <slot/>

            <b-form-group id="email-group" label="Email" label-for="email">
              <b-form-input
                  id="email"
                  name="email"
                  v-model="email"
                  type="email"
                  placeholder="Введите Ваш email"
              ></b-form-input>
            </b-form-group>

            <div class="mt-4">
              <b-button type="submit" variant="primary" class="btn-block">Зарегистрироваться</b-button>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Регистрируясь, Вы соглашаетесь с условиями
                <a
                    href="javascript: void(0);"
                    class="text-primary"
                >Пользовательского соглашения</a>
              </p>
            </div>
          </b-form>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->

      <div class="mt-5 text-center">
        <p>
          {{ $t('register.alreadyHaveAnAccount') }} ?
          <a href="/login" class="font-weight-medium text-primary">{{ $t('login.logIn') }}</a>
        </p>
        <p>
          © {{ new Date().getFullYear() }} <a href="/">InComCRM</a>
        </p>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>
